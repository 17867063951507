import useModal from "@/hooks/useModal"
import NewsPage from "@/pages/News"
import { updateConfigKey } from "@/slices/config"
import { AnimatePresence, motion, MotionProps } from "framer-motion"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import ButtonIcon from "./Widgets/ButtonIcon"
import { useTranslation } from "react-i18next"
import ChooseLanguagePage from "@/pages/ChooseLanguage"
import { LanguageKeys } from "@/i18n"
import { getRoomName } from "@/utils"

const Backdrop = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    gap: 0.25rem;

    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    z-index: 300;

    .buttons-container {
        display: flex;
        gap: 0.5rem;
    }
`
const ModalBox = styled(motion.div)`
    position: relative;
    width: clamp(16rem, 90vw, 40rem);
    height: fit-content;
    max-height: calc(100% - 5rem);
    background-color: var(--background-contrast);
    border-radius: var(--border-radius-m);

    &.trailers {
        aspect-ratio: 1;
        background-color: red;
        height: clamp(16rem, 90vw, 40rem);
        width: clamp(16rem, 90vw, 40rem);
    }
`

const backdropProps: MotionProps = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    transition: {
        ease: "easeIn",
        duration: 0.5,
    },
}

const modalProps: MotionProps = {
    initial: {
        y: "100vh",
    },
    animate: {
        y: "0vh",
    },
    exit: {
        y: "100vh",
    },
    transition: {
        delay: 0.2,
    },
}

const buttonProps: MotionProps = {
    ...modalProps,
    exit: {
        y: "100vh",
        opacity: 0,
    },
    transition: {
        duration: 0.1,
        ease: "easeInOut",
    },
}

// let latestNews = "beta-teaser-christmas"
let latestNews = "march-teaser"

const Modal = () => {
    const lastViewedNews = useSelector((state: IRootState) => state.config.last_viewed_news)
    const language = useSelector((state: IRootState) => state.config.language)
    const { modal, setModal, wrapperProps } = useModal()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleRemoveNews = useCallback(() => {
        dispatch(updateConfigKey({ key: "last_viewed_news", newValue: latestNews }))
    }, [dispatch])

    const handleNoLanguage = () => {
        const userLanguage = //@ts-ignore
            (navigator.language || navigator.userLanguage).split("-")[0]

        let defaultLanguage = "en" as LanguageKeys
        if (["pt", "en", "es "].includes(userLanguage)) defaultLanguage = userLanguage
        // temporário, update do ghile
        if (getRoomName() === "formandos") return

        setModal(<ChooseLanguagePage defaultLang={defaultLanguage} />, "minimum", {
            style: { maxWidth: "27rem" },
            hideCloseButton: true,
            isLanguagePage: true,
        })
    }

    const handleClose = () => {
        if (!wrapperProps?.isLanguagePage) {
            handleRemoveNews()
        }
        setModal(false, "minimum")
    }

    useEffect(() => {
        if (!language) return handleNoLanguage()
        if (lastViewedNews === latestNews) return
        if (getRoomName() === "formandos") return
        setModal(<NewsPage />, "minimum", {
            styles: { aspectRatio: 1 },
        })
    }, [lastViewedNews, language])

    return (
        <AnimatePresence>
            {modal && (
                <Backdrop {...backdropProps}>
                    {!wrapperProps?.hideCloseButton && (
                        <div className="buttons-container">
                            <ButtonIcon
                                wrapperProps={{
                                    className: "close",
                                    ...buttonProps,
                                }}
                                icon="close"
                                onSubmit={handleClose}
                                children={t("close")}
                            />
                        </div>
                    )}
                    <ModalBox {...modalProps} {...wrapperProps}>
                        {modal}
                    </ModalBox>
                </Backdrop>
            )}
        </AnimatePresence>
    )
}

export default Modal
