import { TippyProps } from "@tippyjs/react"
import { ApiAudioDataEvent, ApiAudioDataStatus, ApiVideo } from "@types"
import { BLEND_MODES, Rectangle, Sprite } from "pixi.js"
import { VideoPlayerApi } from "../VideoPlayer"
import { outlineFilterWhite } from "./filters"
import LightTooltip from "./LightTooltip"
import prettyMilliseconds from "pretty-ms"

let timeout: any = null
let interval: any = null
let index: number = 0

const getTimeoutToBeat = (beats: number[]) => beats[index + 1] - beats[index]

// const handleTimeout = (beats: number[]) => {
//     console.log("Beat", index)
//     const time = getTimeoutToBeat(beats)
//     index += 1
//     clearTimeout(timeout)
//     timeout = setTimeout(
//         () => handleTimeout(beats),
//         (time * 1000)
//     )
// }

const handleTimeout = (beats: number[], videoPlayer: VideoPlayerApi, callback: Function) => {
    if (index > beats.length) return console.info("Audio data finalizado", beats.at(-1))
    const time = getTimeoutToBeat(beats)
    index += 1
    clearTimeout(timeout)
    timeout = setTimeout(() => handleTimeout(beats, null, callback), time * 1000)
    callback()
}

interface Props {
    width: number
    currentVideo: ApiVideo
    audioDataEvent: ApiAudioDataEvent
    videoPlayer: VideoPlayerApi
    setTooltipBgProps: (d: TippyProps) => void
    activateTooltipBg: () => void
    deactivateTooltipBg: () => void
    wrapperRef: any
    isTooltipVisible: boolean
    setBgTooltipHover: (d: boolean) => void
    isCanvasHover: boolean
}

// const bgBackground = Sprite.from("https://f.feridinha.com/cxN2n.png")
// const bgLightOn = Sprite.from("https://f.feridinha.com/U17d1.png")
// const bgLightOff = Sprite.from("https://f.feridinha.com/NODPc.png")

const everyFrame = () => {}

const startLightTimeout = ({
    beats,
    videoPlayer,
    bgLightOn,
}: {
    beats: number[]
    videoPlayer: VideoPlayerApi
    bgLightOn: Sprite
}) => {
    let nextBeat: number = null
    let currentTime = videoPlayer.getCurrentTime()
    console.log("Iniciando timeout")
    let i = 0
    for (let beat of beats) {
        if (beat > currentTime) {
            nextBeat = beat - currentTime
            index = i
            break
        }
        i += 1
    }

    timeout = setTimeout(
        () =>
            handleTimeout(beats, videoPlayer, () => {
                if (index % 2 === 0) {
                    bgLightOn.visible = false
                } else {
                    bgLightOn.visible = true
                }
            }),
        nextBeat
    )
}

const background = ({
    width,
    currentVideo,
    audioDataEvent,
    videoPlayer,
    setTooltipBgProps,
    activateTooltipBg,
    deactivateTooltipBg,
    wrapperRef,
    isTooltipVisible,
    isCanvasHover,
    setBgTooltipHover,
}: Props): Sprite[] => {
    let items: Sprite[] = []

    const bgBackground = Sprite.from("https://f.feridinha.com/cxN2n.png")
    // const bgLightOn = Sprite.from("https://f.feridinha.com/U17d1.png")
    // const bgLightOff = Sprite.from("https://f.feridinha.com/NODPc.png")
    // const bgBackground = Sprite.from("https://f.feridinha.com/P3BP3.png")
    bgBackground.anchor.set(0.5, 0)
    bgBackground.x = width / 2
    bgBackground.alpha = 0.5

    items.push(bgBackground)

    const bgLightOn = Sprite.from("https://f.feridinha.com/U17d1.png")
    bgLightOn.anchor.set(0.5, 0)
    bgLightOn.x = width / 2
    bgLightOn.visible = false

    const bgLightOff = Sprite.from("https://f.feridinha.com/NODPc.png")
    bgLightOff.anchor.set(0.5, 0)
    bgLightOff.x = width / 2
    bgLightOff.interactive = true

    const bgLightOffHitArea = new Rectangle(0, 0, 280, 180)
    bgLightOffHitArea.x = -140
    bgLightOff.hitArea = bgLightOffHitArea

    const mouseenter = (e: any) => {
        if (isTooltipVisible || !isCanvasHover) return
        bgLightOff.filters = [outlineFilterWhite]
        e.target.cursor = "pointer"
        const rect = wrapperRef.current.getBoundingClientRect()

        const x = rect.left + rect.width / 2 + 5
        const y = rect.top - 160

        setTooltipBgProps({
            content: <LightTooltip setBgTooltipHover={setBgTooltipHover} />,
            offset: [x, y],
        })
        activateTooltipBg()
    }

    const mouseleave = () => {
        bgLightOff.filters = null
        deactivateTooltipBg()
    }

    bgLightOff.onmouseenter = mouseenter
    bgLightOff.onmouseleave = mouseleave

    const bgFloor = Sprite.from("https://f.feridinha.com/7e1iE.png ")
    bgFloor.anchor.set(0.5, 0)
    bgFloor.x = width / 2
    bgFloor.y = 2

    items.push(bgFloor)
    items.push(bgLightOff)
    items.push(bgLightOn)

    const bgImageL = Sprite.from("https://f.feridinha.com/v7Ygu.png")
    bgImageL.anchor.set(0, 0)
    bgImageL.x = 0
    items.push(bgImageL)

    const bgImageR = Sprite.from("https://f.feridinha.com/6Mva4.png")
    bgImageR.anchor.set(1, 0)
    bgImageR.x = width
    items.push(bgImageR)

    clearTimeout(timeout)
    if (!audioDataEvent || !currentVideo || !videoPlayer.isReady) {
        return items
    }

    if (audioDataEvent.for.uuid !== currentVideo.uuid) {
        return items
    }

    const { beats } = audioDataEvent.audioData
    startLightTimeout({ beats, bgLightOn, videoPlayer })
    clearInterval(interval)
    interval = setInterval(() => {
        startLightTimeout({ beats, bgLightOn, videoPlayer })
    }, 60000)

    return items
}

export default background

/*
    timeout = setTimeout(
        () =>
            handleTimeout(beats, videoPlayer, () => {
                if (index % 2 === 0) {
                    bgLightOnRed.alpha = 0
                    bgLightOnBlue.alpha = 0
                } else {
                    if (lastTurn === "blue") {
                        bgLightOnBlue.alpha = 0
                        bgLightOnRed.alpha = 1
                        lastTurn = "red"
                    } else {
                        bgLightOnBlue.alpha = 1
                        bgLightOnRed.alpha = 0
                        lastTurn = "blue"
                    }
                }
            }),
        nextBeat
    )
*/
